import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import GrantsContext from "../../Context/GrantsContext";
import GrantForm from "../Forms/GrantForm";
import DataTable from "./DataTable";
import Grant from "../../Classes/Grant";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import {
  arrayToString,
  confirmPopup,
  getDateLong,
} from "../../Utilities/general";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import CSVButton from "../UI/CSVButton";

function GrantTable(props) {
  let { loading, grants, addGrant, deleteGrant, updateGrant } =
    useContext(GrantsContext);

  if (loading) return <LoadingIcon />;


  const handleOnSave = (grant) => {
    addGrant(grant);
  };
  const handleOnUpdate = (grant) => {
    updateGrant(grant);
  };
  const handleDelete = (grantId) => {
    if (confirmPopup("grant")) {
      deleteGrant(grantId);
    }

  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      render: (item) => <th>{item.name}</th>,
      headerStyle: { width: "10vw" },
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: { width: "30vw" },
    },
    {
      dataField: "releaseDate",
      text: "Release Date",
      formatter: (cell, row) => (
        <label className="label-white">{getDateLong(cell)}</label>
      ),
    },
    {
      dataField: "deadline",
      text: "Deadline",
      formatter: (cell, row) => (
        <label className="label-white">{getDateLong(cell)}</label>
      ),
    },
    {
      dataField: "link",
      text: "Link",
      headerStyle: { width: "20vw" },
      formatter: (cell, row) => (
        <a href={cell} className="label-white">
          {cell}
        </a>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <label className="label-white">{cell ? "Active" : "Inactive"}</label>
      ),
    },
    {
      dataField: "technologies",
      text: "Technologies",
      headerStyle: { width: "8vw" },
      formatter: (cell, row) => (
        <label className="label-white">{arrayToString(row.technologies)}</label>
      ),
    },
    {
      dataField: "jurisdictions",
      text: "Jurisdictions",
      headerStyle: { width: "8vw" },
      formatter: (cell, row) => (
        <label className="label-white">
          {arrayToString(row.jurisdictions)}
        </label>
      ),
    },
    {
      dataField: "industries",
      text: "Industries",
      formatter: (cell, row) => (
        <label className="label-white">{arrayToString(row.industries)}</label>
      ),
    },
    {
      dataField: "counties",
      text: "Counties",
      formatter: (cell, row) => (
        <label className="label-white">{arrayToString(row.counties)}</label>
      ),
    },
    {

      dataField: "organization",
      text: "Organization",
      headerStyle: { width: "8vw" },
      formatter: (cell, row) => (
        <label className="label-white">{cell.name}</label>
      ),
    },
    {
      dataField: "contacts",
      text: "Contacts",
      formatter: (cell, row) => (
        <label className="label-white">{arrayToString(row.contacts)}</label>
      ),
    },
    {
      dataField: "",
      headerStyle: { width: "6vw" },
      text: "Edit",
      formatter: (cell, row) => (
        <GrantForm saveGrant={handleOnUpdate} grant={row} display="Edit" />
      ),
    },
    {
      dataField: "",
      text: "Delete",
      headerStyle: { width: "7vw" },
      formatter: (cell, row) => {
        return (
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-danger"
          >
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <GrantForm
        grant={new Grant()}
        display="Create Grant"
        saveGrant={handleOnSave}
      />
      <br></br>
      <div className="CSVText">
        <CSVButton name="grants" />
        Download Grants
      </div>
      <br></br>
      <div className="CSVText">
      <CSVButton name="subscribers" />
      Download Subscribers
      </div>

      <DataTable data={grants} columns={columns} />
    </React.Fragment>
  );
}

export default GrantTable;
