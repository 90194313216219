import React, { useState, useContext } from "react";
import Case from "case";
import Accordion from "react-bootstrap/Accordion";
import LookupContext from "../../Context/LookupContext";
import "../CSS_Files/master.css";
import GrantsContext from "../../Context/GrantsContext";

const FilterItem = (props) => {
  const [chosenFilters, setChosenFilters] = useState([]);
  const [open, setOpen] = useState(false);
  let { name, items, filterName } = props;
  name = name === "organizations" ? "organization" : name;
  filterName = filterName === "organizations" ? "organization" : filterName;

  const { updateFilters, filters } = useContext(GrantsContext);

  //Upon click of a checkbox, this will run and update the chosenFilters state
  const handleClick = (e) => {
    const { name: itemName, checked } = e.target;
    if (checked) {
      updateFilters(filterName, [...chosenFilters, itemName]);
      setChosenFilters((prev) => [...prev, itemName]);
    } else {
      const newFilters = chosenFilters.filter((item) => item !== itemName);
      updateFilters(filterName, newFilters);
      setChosenFilters(newFilters);
    }
  };
  return (
    <div>
      <Accordion defaultActiveKey="0" className="filter-accordion">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="evolve-text-filter-item">
            {`${Case.capital(name)}`}
          </Accordion.Header>
          <Accordion.Body>
            {items &&
              items.map((item, index) => (
                <React.Fragment key={index}>
                  <input
                    onChange={handleClick}
                    name={item.name}
                    type="checkbox"
                  ></input>
                  &nbsp;
                  <label className="evolve-text-filter-item-options">
                    {" "}
                    {Case.capital(item.name)}
                  </label>
                  <br></br>
                </React.Fragment>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default FilterItem;
