import { v4 as uuid } from "uuid";
import moment from "moment";

class Grant {
  constructor() {
    this.id = uuid();
    this.name = "";
    this.description = "";
    this.link = "";
    this.status = false;
    this.deleted = false;
    this.deadline = moment();
    this.releaseDate = moment();
    this.createdAt = moment();
    this.technologies = [];
    this.counties = [];
    this.jurisdictions = [];
    this.industries = [];
    this.contacts = [];
    this.organization = {};
  }
  calcIsNew() {
    const today = moment();
    const diff = today.diff(this.createdAt, "days");
    if (diff < 30) {
      return true;
    } else {
      return false;
    }
  }
}

export default Grant;
