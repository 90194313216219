import axios from "axios";
import { tokenDeconstruct } from "../Utilities/tokens";

const baseUrl = process.env.REACT_APP_API_KEY;

export const callAllUsers = async () => {
  try {
    const response = await axios({
      url: `${baseUrl}/users`,
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

//Returns an auth token
export const verifyCredentials = async (userCredentials) => {
  try {
    const response = await axios({
      url: `${baseUrl}/users/login`,
      method: "post",
      mode: "cors",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(userCredentials),
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const verifyPassword = async (data) => {
  try {
    console.log(data.oldPassword)
    const response = await axios({
      url: `${baseUrl}/users/verify-password`,
      method: "post",
      mode: "cors",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data: {
        email: data.email,
        password: data.oldPassword
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

//returns an auth token
export const register = async (user) => {
  delete user.admin;
  try {
    const response = await axios({
      url: `${baseUrl}/users/register`,
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: user,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

//returns a user object
export const authenticateUser = async (token) => {
  try {
    const response = await axios({
      url: `${baseUrl}/users/authenticate`,
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: token,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const updateUser = async (user) => {
  try {
    const response = await axios({
      url: `${baseUrl}/users`,
      method: "put",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: user,
    });
    return true;
  } catch (er) {
    await er;
    console.log(er);
    return false;
  }
};

export const createSubscription = async (subscription) => {
  try {
    const response = axios({
      url: `${baseUrl}/users/subscribe`,
      method: "post",
      mode: "cors",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      data: subscription,
    });
  } catch (er) {
    await er;
    console.log(er);
  }
};
