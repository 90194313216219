import React from "react";
import { useContext } from "react";
import "../../CSS_Files/master.css";
import UserContext from "../../../Context/UserContext";
import { Button } from "react-bootstrap";

const GrantButton = (props) => {
  const { user } = useContext(UserContext);
    if (user.admin) {
    return (
      <div classname="container">
        <form action="https://main.evolvehoustongrants.org/" method="get" target="_blank">
          <Button type="submit" className="grant-page-button">
            Main Page
          </Button>
        </form>
      </div>
    );}
    else {
      return <div></div>;
    }
  };

export default GrantButton;
