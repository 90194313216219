import { Routes, Route } from "react-router-dom";
import LoginCard from "../UserLogin/LoginCard";
import GrantsPage from "../Pages/GrantsPage";
import AdminPage from "../Pages/AdminPage";
import ContactTable from "../Tables/ContactTable";
import GrantTable from "../Tables/GrantTable";
import TestPage from "./../Pages/TestPage";
import OrganizationTable from "../Tables/OrganizationTable";
import GenericLookupTable from "../Tables/GenericTable";
import UserTable from "../Tables/UserTable";
import LoadingPage from "../Pages/LoadingPage";
import RequireAuth from "./RequireAuth";
import RequireAdmin from "./RequireAdmin";
import InteractionTable from "../Tables/InteractionTable";
import ReportTable from "../Tables/ReportTable";
import GraphTable from "../Tables/GraphTable";

const RoutePaths = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <GrantsPage />
          </RequireAuth>
        }
        exact
      />
      <Route
        path="/newsso/:ssonew"
        element={
          <RequireAuth>
            <GrantsPage />
          </RequireAuth>
        }
        exact
      />
      <Route
        path="/admin"
        element={
          <RequireAdmin>
            <AdminPage />
          </RequireAdmin>
        }
      />
      <Route
        path="/admin/grants"
        element={
          <RequireAdmin>
            <AdminPage>
              <GrantTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        path="/admin/contacts"
        element={
          <RequireAdmin>
            <AdminPage>
              <ContactTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        path="/admin/organizations"
        element={
          <RequireAdmin>
            <AdminPage>
              <OrganizationTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        path="/admin/users"
        element={
          <RequireAdmin>
            <AdminPage>
              <UserTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        exact
        path="/admin/:table"
        element={
          <RequireAdmin>
            <AdminPage>
              <GenericLookupTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        exact
        path="/admin/interactions"
        element={
          <RequireAdmin>
            <AdminPage>
              <InteractionTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        exact
        path="/admin/graphtable"
        element={
          <RequireAdmin>
            <AdminPage>
              <GraphTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route
        exact
        path="/admin/reports"
        element={
          <RequireAdmin>
            <AdminPage>
              <ReportTable />
            </AdminPage>
          </RequireAdmin>
        }
      />
      <Route path="/login" element={<LoginCard />} />
      <Route path="/loading" element={<LoadingPage />} />
    </Routes>
  );
};

export default RoutePaths;
