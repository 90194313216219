import React from "react";
import { getCsv } from "../../Calls/csv";

var csvFileData = [
  ["AlanWalker", "Singer"],
  ["CristianoRonaldo", "Footballer"],
  ["SainaNehwal", "BadmintonPlayer"],
  ["ArijitSingh", "Singer"],
  ["TerenceLewis", "Dancer"],
];

async function download_csv_file() {
  const data = await getCsv("logins");
  //definetheheadingforeachrowofthedata
  // var csv='Name,Profession\n';

  let csv = data[0];

  //mergethedatawithCSV
  csvFileData.forEach(function (row) {
    csv += row.join(",");
    csv += "\n";
  });

  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
  hiddenElement.target = "_blank";

  //providethenamefortheCSVfiletobedownloaded
  hiddenElement.download = "logins.csv";
  hiddenElement.click();
}

function TestPage(props) {
  return (
    <div>
      This is the test page
      <button
        onClick={() => {
          getCsv("logins");
        }}
      >
        Get logins
      </button>
      <button onClick={download_csv_file}>download</button>
    </div>
  );
}

export default TestPage;
