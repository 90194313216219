import React, { useContext, useRef } from "react";
import DataTable from "./DataTable";
import LookupContext from "../../Context/LookupContext";
import { useParams } from "react-router-dom";
import GenericForm from "../Forms/GenericForm";
import { callCreate, callDelete, callUpdate } from "../../Calls/generic";
import { confirmPopup } from "../../Utilities/general";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

function GenericTable(props) {
  const { table } = useParams();
  const { lookups, updateLookup, addLookup, deleteLookup } =
    useContext(LookupContext);

  const data = useRef();

  if (lookups[table]) {
    data.current = lookups[table];
  }

  const handleUpdateSave = async (item) => {
    updateLookup(item, table);
  };
  const handleDelete = (id) => {
    if (confirmPopup()) {
      deleteLookup(id, table);
    }
  };

  const handleNewSave = async (item) => {
    addLookup(item, table);
  };

  if (!lookups[table])
    return <label className="label-white">No Items found</label>;
  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "",
      text: "Edit",
      headerStyle: { width: "1vw" },

      formatter: (cell, row) => (
        <GenericForm
          display={`Edit ${table}`}
          item={row}
          saveItem={handleUpdateSave}
        />
      ),
    },
    {
      dataField: "",
      text: "Delete",
      headerStyle: { width: "1vw" },

      formatter: (cell, row) => (
        <button onClick={() => handleDelete(row.id)} className="btn btn-danger">
          Delete
        </button>
      ),
    },
  ];
  return (
    <React.Fragment>
      <GenericForm
        item={{ id: 0, name: "" }}
        saveItem={handleNewSave}
        display={`Create ${table}`}
      />
      <DataTable
        data={lookups[table]}
        filter={filterFactory()}
        columns={columns}
      />
    </React.Fragment>
  );
}

export default GenericTable;
