import Form from "react-bootstrap/Form";
import React from "react";

const CheckboxList = (props) => {
  const { options, items, name, display, onChange } = props;
  if (!options || !items || !name || !onChange) return null;

  const handleUpdate = (e) => {
    const value = JSON.parse(e.target.value);
    const { checked } = e.target;
    const newItems = [...items];
    if (checked) {
      newItems.push(value);
    } else {
      newItems.splice(newItems.indexOf(value), 1);
    }
    onChange({
      target: {
        name,
        value: [...newItems],
      },
    });
  };

  return (
    <div>
      {options.map((option, index) => (
        <div key={index}>
          <input
            checked={items && items.find((i) => i.id === option.id)}
            type={"checkbox"}
            value={JSON.stringify(option)}
            onChange={handleUpdate}
          />
          <label>{option.name}</label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
