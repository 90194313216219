const colors = ["#7000ff", "#00ffd7", "#76fc44", "#deff00", "#000000", "#a6a8ab", "#a68dff", "#1affe2"];
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getGrantBarData = (interactions, grantNames, interactionType) => {
    
    //const filteredInteractions = interactions.filter(interaction => interaction.interaction_type_id === interactionType);
    let data = grantNames.map(grantName => {
      return {
        grant_name: grantName,
        likes: Array(12).fill(0)
      };
    });
  
    for (let i = 0; i < interactions.length; i++) {
      const interaction = interactions[i];
      const monthIndex = interaction.month - 1;
  
      if (monthIndex >= 0 && monthIndex < 12) {
        const grantIndex = grantNames.indexOf(interaction.grant_name);
        data[grantIndex].likes[monthIndex] = interaction.likes;
      }
    }
  
    const barData = {
      labels: months,
      responsive: true,
      offset: true,
      datasets: data.map((grantData, index) => {
        return {
          label: grantData.grant_name,
          data: grantData.likes,
          backgroundColor: colors[index % colors.length],
          barThickness: 30,
          categoryPercentage: 1,
        };
      }),
    };
  
    return barData;
  };  

export const getGrantBarDataSubscriptions = (interactions, grantNames) => {
    
    let subsData = grantNames.map(grantName => {
      return {
        grant_name: grantName,
        subscriptions: Array(12).fill(0)
      };
    });
  
    for (let i = 0; i < interactions.length; i++) {
      const interaction = interactions[i];
      const monthIndex = interaction.month - 1; 
  
      if (monthIndex >= 0 && monthIndex < 12) {
        const grantIndex = grantNames.indexOf(interaction.grant_name);
        subsData[grantIndex].subscriptions[monthIndex] = interaction.subscriptions;
      }
    }
  
    const barData = {
      labels: months,
      responsive: true,
      offset: true,
      datasets: subsData.map((grantData, index) => {
        return {
          label: grantData.grant_name,
          data: grantData.subscriptions,
          backgroundColor: colors[index % colors.length],
          barThickness: 30,
          categoryPercentage: 1,
        }
      }),
    };

    return barData;
  };

  export const getGrantBarDataInquiries = (interactions, grantNames) => {
    
    let inquiriesData = grantNames.map(grantName => {
      return {
        grant_name: grantName,
        inquiries: Array(12).fill(0)
      };
    });
  
    for (let i = 0; i < interactions.length; i++) {
      const interaction = interactions[i];
      const monthIndex = interaction.month - 1; 
  
      if (monthIndex >= 0 && monthIndex < 12) {
        const grantIndex = grantNames.indexOf(interaction.grant_name);
        inquiriesData[grantIndex].inquiries[monthIndex] = interaction.inquiries;
      }
    }
  
    const barData = {
      labels: months,
      responsive: true,
      offset: true,
      datasets: inquiriesData.map((grantData, index) => {
        return {
          label: grantData.grant_name,
          data: grantData.inquiries,
          backgroundColor: colors[index % colors.length],
          barThickness: 30,
          categoryPercentage: 1,
        }
      }),
    };

    return barData;
  };

  export const getGrantBarDataLinks = (interactions, grantNames) => {
    
    let linksData = grantNames.map(grantName => {
      return {
        grant_name: grantName,
        link_clicks: Array(12).fill(0)
      };
    });
  
    for (let i = 0; i < interactions.length; i++) {
      const interaction = interactions[i];
      const monthIndex = interaction.month - 1; 
  
      if (monthIndex >= 0 && monthIndex < 12) {
        const grantIndex = grantNames.indexOf(interaction.grant_name);
        linksData[grantIndex].link_clicks[monthIndex] = interaction.link_clicks;
      }
    }
  
    const barData = {
      labels: months,
      responsive: true,
      offset: true,
      datasets: linksData.map((grantData, index) => {
        return {
          label: grantData.grant_name,
          data: grantData.link_clicks,
          backgroundColor: colors[index % colors.length],
          barThickness: 30,
          categoryPercentage: 1,
        }
      }),
    };

    return barData;
  };

  export const getGrantBarDataMoreInfo = (interactions, grantNames) => {
    
    let linksData = grantNames.map(grantName => {
      return {
        grant_name: grantName,
        more_info_clicks: Array(12).fill(0)
      };
    });
  
    for (let i = 0; i < interactions.length; i++) {
      const interaction = interactions[i];
      const monthIndex = interaction.month - 1; 
  
      if (monthIndex >= 0 && monthIndex < 12) {
        const moreInfoIndex = grantNames.indexOf(interaction.grant_name);
        linksData[moreInfoIndex].more_info_clicks[monthIndex] = interaction.more_info_clicks;
      }
    }
  
    const barData = {
      labels: months,
      responsive: true,
      offset: true,
      datasets: linksData.map((grantData, index) => {
        return {
          label: grantData.grant_name,
          data: grantData.more_info_clicks,
          backgroundColor: colors[index % colors.length],
          barThickness: 30,
          categoryPercentage: 1,
        }
      }),
    };

    return barData;
  };

  export const getGrantStackedBarData = (interactions, grantNames) => {
    let inquiriesValues = [];
    let likesValues = [];
    let linkClicksValues = [];
    let moreInfoClicksValues = [];
    let subscriptionsValues = [];

    for (let i = 0; i < interactions.length; i++) {
      inquiriesValues.push(interactions[i].inquiries);
      likesValues.push(interactions[i].likes);
      linkClicksValues.push(interactions[i].link_clicks);
      moreInfoClicksValues.push(interactions[i].more_info_clicks);
      subscriptionsValues.push(interactions[i].subscriptions);
    }
    
    const stackedBarData = {
      labels: grantNames,
      responsive: true,
      offset: true,
      datasets: [
        {
          label: "Inquiries",
          data: inquiriesValues,
          backgroundColor: "#7000ff",
          barThickness: 45,
        },
        {
          label: "Likes",
          data: likesValues,
          backgroundColor: "#00ffd7",
          barThickness: 45,
        },
        {
          label: "Link Clicks",
          data: linkClicksValues,
          backgroundColor: "#76fc44",
          barThickness: 45,
        },
        {
          label: "More Info Clicks",
          data: moreInfoClicksValues,
          backgroundColor: "#deff00",
          barThickness: 45,
        },
        {
          label: "Subscriptions",
          data: subscriptionsValues,
          backgroundColor: "#a6a8ab",
          barThickness: 45,
        },
      ],
    };
    
    return stackedBarData;
  };

  export const stackedBarPlotOptions = {
    scales: {
      y: {
        min: 0
      }
    },
    offsetGridLines: true,
    drawTicks: true,
    layout: {
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
      },
    },
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      labels: {
        usePointStyle: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          padding: 5,
        },
        gridLines: {
          display: false,
        },
      },
      y: {
        stacked: true,
        gridLines: {
          drawBorder: false,
        },
        
      },
    },
  }