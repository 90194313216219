import React, { useState, useRef, useEffect } from "react";
import { callAll, callCreate, callUpdate, callDelete } from "../Calls/generic";

const useLookups = () => {
  const [lookups, setLookups] = useState([]);
  const refLookups = useRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCounties = async () => {
    let data = await callAll("counties");
    setLookups((prevLookups) => ({ ...prevLookups, counties: data }));
    refLookups.current = { ...refLookups.current, counties: data };
  };
  const getJurisdictions = async () => {
    let data = await callAll("jurisdictions");
    setLookups((prevLookups) => ({ ...prevLookups, jurisdictions: data }));
    refLookups.current = { ...refLookups.current, jurisdictions: data };
  };
  const getIndustries = async () => {
    let data = await callAll("industries");
    setLookups((prevLookups) => ({ ...prevLookups, industries: data }));
    refLookups.current = { ...refLookups.current, industries: data };
  };
  const getTechnologyTypes = async () => {
    let data = await callAll("technologies");
    setLookups((prevLookups) => ({ ...prevLookups, technologies: data }));
    refLookups.current = { ...refLookups.current, technologies: data };
  };
  const getOrganizations = async () => {
    let data = await callAll("organizations");
    setLookups((prevLookups) => ({ ...prevLookups, organizations: data }));
    refLookups.current = { ...refLookups.current, organizations: data };
  };
  const getContacts = async () => {
    let data = await callAll("contacts");
    setLookups((prevLookups) => ({ ...prevLookups, contacts: data }));
    refLookups.current = { ...refLookups.current, contacts: data };
  };
  const getUsers = async () => {
    let data = await callAll("users");
    setLookups((prevLookups) => ({ ...prevLookups, users: data }));
    refLookups.current = { ...refLookups.current, users: data };
  };

  const addLookup = async (item, name) => {
    try {
      await callCreate(item, name);
      let data = await callAll(name);
      refLookups.current = { ...refLookups.current, [name]: data };
      setLookups(refLookups.current);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteLookup = (lookupId, name) => {
    try {
      callDelete(lookupId, name);
      refLookups.current = {
        ...refLookups.current,
        [name]: refLookups.current[name].filter((item) => item.id !== lookupId),
      };
      setLookups(refLookups.current);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateLookup = async (item, name) => {
    try {
      refLookups.current = {
        ...refLookups.current,
        [name]: refLookups.current[name].map((i) =>
          i.id === item.id ? item : i
        ),
      };

      setLookups(refLookups.current);

      await callUpdate(item, name);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCounties();
    getContacts();
    getJurisdictions();
    getIndustries();
    getTechnologyTypes();
    getOrganizations();
    getUsers();
  }, []);

  return {
    lookups,
    loading,
    error,
    addLookup,
    deleteLookup,
    updateLookup,
  };
};

export default useLookups;
