import React, { useContext } from "react";
import DataTable from "./DataTable";
import LookupContext from "../../Context/LookupContext";
import ContactForm from "../Forms/ContactForm";
import Contact from "./../../Classes/Contact";
import { confirmPopup } from "../../Utilities/general";

function ContactTable(props) {
  const { lookups, updateLookup, deleteLookup, addLookup } =
    useContext(LookupContext);
  const { contacts } = lookups;

  if (!contacts)
    return <label className="label-white">No contacts found</label>;

  const handleUpdateSave = (item) => {
    updateLookup(item, "contacts");
  };

  const handleDelete = (id) => {
    if (confirmPopup()) {
      deleteLookup(id, "contacts");
    }
  };

  const handleNewSave = (item) => {
    addLookup(item, "contacts");
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "link",
      text: "Link",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "organization",
      text: "Organization",
      formatter: (cell, row) => (
        <label className="label-white">{cell.name}</label>
      ),
    },
    //Define what the Actions column will render by calling the Form
    //Component and passing in the object and the handleOnSave function
    {
      dataField: "",
      text: "Edit",
      headerStyle: { width: "3vw" },

      formatter: (cell, row) => (
        <ContactForm
          item={row}
          saveItem={handleUpdateSave}
          display="Edit Contact"
        />
      ),
    },
    {
      dataField: "",
      text: "Delete",
      headerStyle: { width: "3vw" },

      formatter: (cell, row) => (
        <button onClick={() => handleDelete(row.id)} className="btn btn-danger">
          Delete
        </button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ContactForm
        item={new Contact()}
        saveItem={handleNewSave}
        display="Create Contact"
      />
      <DataTable data={contacts} columns={columns} />
    </React.Fragment>
  );
}

export default ContactTable;
