import React from 'react'

function InputField(props) {
  const { label, name, value, placeholder, onChange, required } = props
  return (
    <div className="mb-3" controlId={`inputField-${name}`}>
      <label className="create-account-input">{label}</label>
      <input
        required={required}
        value={value}
        type="text"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className="form-control"
      />
      <div className="valid-feedback">Looks good!</div>
    </div>
  )
}

export default InputField;
