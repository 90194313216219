import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../CSS_Files/master.css";
import UserContext from "../../Context/UserContext";

function Logout() {
  const { logoutUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    navigate("/login", { replace: true });
  };

  return (
    <div className="container">
      <Button onClick={handleLogout} className="logout">
        Logout
      </Button>
    </div>
  );
}

export default Logout;
