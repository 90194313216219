import axios from "axios";

const baseUrl = process.env.REACT_APP_API_KEY + "/csv";

export const getCsv = async (path) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${path}`,
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    console.log(response.data);
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};
