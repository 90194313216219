import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GrantsContext from "./Context/GrantsContext";
import LookupContext from "./Context/LookupContext";
import RoutePaths from "./Components/Routes/RoutePaths";
import UserContext from "./Context/UserContext";
import { endSession } from "./Calls/interaction";
import useUserAuth from "./Hooks/useUserAuth";
import useLookups from "./Hooks/useLookups";
import useGrants from "./Hooks/useGrants";

function App(props) {
  const [filters, setFilters] = useState([]);
  const grantsMgt = useGrants();
  const userAuthMgt = useUserAuth();
  const lookupsMgt = useLookups();
  const { user } = userAuthMgt;

  const updateFilters = (filterName, filterValue) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, [filterName]: filterValue };
    });
  };

  return (
    <Router>
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/acw2slt.css"
        ></link>
        <GrantsContext.Provider
          value={{ ...grantsMgt, filters, updateFilters }}
        >
          <UserContext.Provider value={userAuthMgt}>
            <LookupContext.Provider value={lookupsMgt}>
              <RoutePaths />
            </LookupContext.Provider>
          </UserContext.Provider>
        </GrantsContext.Provider>
      </React.Fragment>
    </Router>
  );
}
export default App;
