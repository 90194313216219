import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import LookupContext from "./../../Context/LookupContext";
import "../CSS_Files/master.css";
import DropdownList from "../UI/DropdownList";

const OrganizationForm = (props) => {
  const { item, saveItem, display } = props;

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formItem, setFormItem] = useState({ ...item });

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setFormItem({ ...formItem, [name]: value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    saveItem(formItem);
    handleClose();
  };

  return (
    <div>
      <Container>
        <Button className="create-form-button" onClick={handleShow}>
          {display}
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="needs-validation" onSubmit={handleSave} novalidate>
              <div className="mb-3">
                <label className="label-bold">Name</label>
                <input
                  required
                  value={formItem.name}
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleUpdate}
                  autoFocus
                  className="form-control"
                />
                <div className="valid-feedback">Looks good!</div>

                <label className="label-bold">Abberviation of Name</label>
                <input
                  required
                  value={formItem.abbreviation}
                  type="text"
                  className="form-control"
                  name="abbreviation"
                  placeholder="Abbreviation not required"
                  onChange={handleUpdate}
                  autoFocus
                  isInvalid={formItem.abbreviation === ""}
                />
                <div className="valid-feedback">Looks good!</div>

                <label className="label-bold">Website Link</label>
                <input
                  required
                  value={formItem.link}
                  type="link"
                  name="link"
                  placeholder="Website Link"
                  className="form-control"
                  onChange={handleUpdate}
                  autoFocus
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="mb-3">
                <label className="label-bold">Sector</label>
                <DropdownList
                  required
                  items={[{ name: "Public" }, { name: "Private" }]}
                  name="sector"
                  handleUpdate={({ target }) =>
                    handleUpdate({
                      target: { name: target.name, value: target.value.name },
                    })
                  }
                  value={formItem.sector}
                />

                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="mb-3">
                <Button variant="secondary" type="submit">
                  Save
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default OrganizationForm;
