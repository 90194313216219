import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import logo from "../../../Icons/EvolveHoustonIconBLACK.png";
import "./AdminNavbar.css";

const AdminNavbar = (props) => {
  return (
    <Navbar bg="black" variant="dark">
      <Container>
        <Navbar.Brand>Evolve Houston</Navbar.Brand>
        <Nav className="me-auto">
          <NavLink className="nl-item" to="/admin/grants">
            Grants
          </NavLink>
          <NavLink className="nl-item" to="/admin/contacts">
            Contacts
          </NavLink>
          <NavLink className="nl-item" to="/admin/organizations">
            Organizations
          </NavLink>
          <NavLink className="nl-item" to="/admin/counties">
            Counties
          </NavLink>
          <NavLink className="nl-item" to="/admin/jurisdictions">
            Jurisdictions
          </NavLink>
          <NavLink className="nl-item" to="/admin/industries">
            Industries
          </NavLink>
          <NavLink className="nl-item" to="/admin/technologies">
            Technologies
          </NavLink>
          <NavLink className="nl-item" to="/admin/users">
            Users
          </NavLink>
          <NavLink className="nl-item" to="/admin/interactions">
            Interactions
          </NavLink>
          <NavLink className="nl-item" to="/admin/reports">
            Reports
          </NavLink>
          <NavLink className="nl-item" to="/admin/graphtable">
            Graph Table
          </NavLink>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
