import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import "../CSS_Files/master.css";
import UserContext from "../../Context/UserContext";
import emailjs from 'emailjs-com'

function GenericContactUsForm() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const { user } = useContext(UserContext);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gmail', 'grant_inquiry', e.target, 'FACZtCuwOFy1CyOyW') //serviceID, templateID, target, public key
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });  
        e.target.reset();
    }

  return (
    <Container>
      <Button
        id="contact-button"
        className="generic-contact-button"
        onClick={handleShow}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.46461 16.2416H0L5.62474 0H15.6595L8.88784 12.8223H18L3.94451 30L8.46461 16.2416Z"
            fill="#DEFF00"
          ></path>
        </svg>
        <span className="generic-contact-button">CONTACT US</span>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="contact-modal-header" closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container">
                <form onSubmit={sendEmail}>
                    <div>
                        <div>
                            <input
                                className='form-control'
                                name='name'
                                value={user.firstName + " " + user.lastName}
                                readOnly={true}
                                hidden={true}
                            />
                        </div>
                        <div>
                            <input
                                className='form-control'
                                name='email'
                                value={user.email}
                                readOnly={true}
                                hidden={true}
                            />
                        </div>
                        <div>
                            <input
                                className='form-control'
                                name='subject'
                                value='General Inquiry'
                                readOnly={true}
                                hidden={true}
                            />
                        </div>
                        <div className='col-9 form-group pt-3 mx-auto'>
                            <textarea
                                className='form-control'
                                id=''
                                cols='30'
                                rows='9'
                                placeholder='Your Message'
                                name='message'
                            />
                        <div className='pt-3 mx-auto'>
          <Button className="send-button" type="submit" onClick={handleClose}>
            Send
          </Button>
          <Button className="close-button" onClick={handleClose}>
            Close
          </Button>
                        </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default GenericContactUsForm;
