import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import "../CSS_Files/master.css";
import UserContext from "../../Context/UserContext";
import emailjs from 'emailjs-com';

function ContactUsForm(props) {
  //Requires an item to be passed in that will be manipulated
  //Expects an setItem attribute that will be called when fields are changed
  //This should update the state of the item that is passed in
  //Requires saveItem attribute to determine which axios call to make and
  //whether it is a post or put call
  //display is needed to determine what the button will say

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [body, setBody] = useState("");
  const { user } = useContext(UserContext);
  const { grant } = props;

  if (!props.grant) return null;

    function sendEmail(e) {
       e.preventDefault();

        emailjs.sendForm('gmail', 'grant_inquiry', e.target, 'FACZtCuwOFy1CyOyW') //serviceID, templateID, target, public key
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });  
        e.target.reset();
    }

    return (
      <Container>
        <Button
          id="contact-button"
          className="specific-contact-button"
          onClick={handleShow}
        >
          Ask us about this Grant
        </Button>
  
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="contact-modal-header" closeButton>
            <Modal.Title>Ask A Question about {grant.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="container">
                  <form onSubmit={sendEmail}>
                      <div>
                          <div>
                              <input
                                 className='form-control'
                                  name='name'
                                  value={user.firstName + " " + user.lastName}
                                  readOnly={true} // ...force the input's value to match the state variable...
                                  hidden={true}
                              />
                          </div>
                          <div>
                              <input
                                  className='form-control'
                                  name='email'
                                  value={user.email}
                                  readOnly={true}
                                  hidden={true}
                              />
                          </div>
                          <div>
                              <input
                                 // type="text"
                                  className='form-control'
                                 // placeholder='Subject'
                                  name='subject'
                                  value={grant.name}
                                  readOnly={true}
                                  hidden={true}
                              />
                          </div>
                          <div className='col-9 form-group pt-3 mx-auto'>
                              <textarea
                                  className='form-control'
                                  id=''
                                  cols='30'
                                  rows='9'
                                  placeholder='Your Message'
                                  name='message'
                              />
                          <div className='pt-3 mx-auto'>
            <Button className="send-button" type="submit" onClick={handleClose}>
              Send
            </Button>
            <Button className="close-button" onClick={handleClose}>
              Close
            </Button>
                          </div>
                          </div>
                      </div>
                  </form>
              </div>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }

export default ContactUsForm;
