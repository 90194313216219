import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import LookupContext from "../../Context/LookupContext";
import moment from "moment";
import CheckboxList from "../UI/CheckboxList";
import "../CSS_Files/master.css";
import DropdownList from "../UI/DropdownList";
import { callTrackInteraction } from "../../Calls/interaction";
import UserContext from "../../Context/UserContext";

function GrantForm(props) {
  //Requires a display prop upon calling. Will place text in modal pop up button
  //Requires a grant prop upon calling. Will populate form with grant data
  //    If it is a new grant you are creating, then pass in a new instance of the Grant object
  const { display, grant, saveGrant } = props;

  const { lookups } = useContext(LookupContext);
  const {
    technologies,
    counties,
    jurisdictions,
    contacts,
    industries,
    organizations,
  } = lookups;

  //cw object of a grant to the screen
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formItem, setFormItem] = useState({ ...grant });

  const { user } = useContext(UserContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const trackUpdateGrant = async (data) => {
    await callTrackInteraction({email: data.email, grantId: grant.id, interactionType: "grant_update"});
  }

  const handleUpdateGrant = (e) => {
    const { name, value } = e.target;
    setFormItem({ ...formItem, [name]: value });
  };

  const handleSaveGrant = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    saveGrant(formItem);

    trackUpdateGrant(user);

    handleClose();
  };
  return (
    <Container>
      <Button className="create-form-button" onClick={handleShow}>
        {display}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{display}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="needs-validation"
            onSubmit={handleSaveGrant}
            novalidate
          >
            <div className="mb-3">
              <label className="label-bold">Grant Name</label>
              <input
                required
                value={formItem.name}
                type="text"
                name="name"
                placeholder="Grant Name"
                onChange={handleUpdateGrant}
                className="form-control"
                autoFocus
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Grant Description</label>
              <textarea
                required
                value={formItem.description}
                name="description"
                placeholder="Give a brief description of the grant."
                onChange={handleUpdateGrant}
                rows={3}
                className="form-control"
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Grant Release Date</label>
              <input
                required
                value={new moment(formItem.releaseDate).format("YYYY-MM-DD")}
                name="releaseDate"
                type="date"
                onChange={handleUpdateGrant}
                className="form-control"
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Grant Close Date</label>
              <input
                required
                value={new moment(formItem.deadline).format("YYYY-MM-DD")}
                name="deadline"
                type="date"
                onChange={handleUpdateGrant}
                className="form-control"
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Grant Link</label>
              <input
                value={formItem.link}
                name="link"
                type="text"
                placeholder="Grant Link"
                onChange={handleUpdateGrant}
                className="form-control"
                required
              />
            </div>
            <div>
              <label className="label-bold">Technologies</label>
              <CheckboxList
                options={technologies}
                items={formItem.technologies}
                name="technologies"
                onChange={handleUpdateGrant}
              />
            </div>
            <div>
              <label className="label-bold">Counties</label>
              <CheckboxList
                options={counties}
                items={formItem.counties}
                name="counties"
                onChange={handleUpdateGrant}
              />
            </div>
            <div>
              <label className="label-bold">Organizations</label>
              <DropdownList
                items={organizations}
                name="organization"
                handleUpdate={handleUpdateGrant}
                value={formItem.organization}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Contacts</label>
              <CheckboxList
                options={contacts}
                items={formItem.contacts}
                name="contacts"
                onChange={handleUpdateGrant}
              />
            </div>
            <div>
              <label className="label-bold">Jurisdictions</label>
              <CheckboxList
                options={jurisdictions}
                items={formItem.jurisdictions}
                name="jurisdictions"
                onChange={handleUpdateGrant}
              />
            </div>
            <div>
              <label className="label-bold">Industries</label>
              <CheckboxList
                options={industries}
                items={formItem.industries}
                name="industries"
                onChange={handleUpdateGrant}
              />
            </div>
            <div className="mb-3">
              <Button variant="secondary" type="submit">
                Save
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default GrantForm;
