import React from "react";
import { useContext } from "react";
import "../../CSS_Files/master.css";
import UserContext from "../../../Context/UserContext";

const AdminButton = (props) => {
  const { user } = useContext(UserContext);
    if (user.admin) {
    return (
      <div>
        <form action="https://main.evolvehoustongrants.org/admin" method="get" target="_blank">
          <button type="submit" className="glow-on-hover">
            ADMIN
          </button>
        </form>
      </div>
    );}
    else {
      return <div></div>;
    }
  };

export default AdminButton;
