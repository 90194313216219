import React, { useState, useContext } from 'react'
import { Button, Form, Row, Modal, Container } from 'react-bootstrap'
import '../CSS_Files/master.css'
import { confirmDeactivation } from '../../Utilities/general'
import ChangePasswordForm from './ChangePasswordForm'
import InputField from './InputField'


function UserAccountForm(props) {
  const { handleSave, newUser, activeUser, user, open} = props

  const [show, setShow] = useState(open ? true : false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [deactivate, setDeactivate] = useState(false)
  const [userProfile, setUserProfile] = useState({ ...user })

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserProfile({ ...userProfile, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!userProfile.commercial) {
      userProfile.organizationName = ''
      userProfile.notes = ''
    }
    e.preventDefault()
    if (password === confirmPassword) {
        setPasswordMatch(true);
        userProfile.password = confirmPassword;

        handleSave(userProfile)
        setShow(false)
    } else {
     setPasswordMatch(false);
    }
  }

  const handleDeactivate = () => { // Currently No Code Deactivating account
    if(confirmDeactivation()) {
      userProfile.isActive = false
      window.location.href = "https://main.evolvehoustongrants.org/login";
    }
  }

  const handlePasswordButtonClick = () => {
    console.log('passwordShow:', passwordShow);

    setPasswordShow(true);
    
    console.log('passwordShow:', passwordShow);

  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <Container>
      <Button className="account-button" onClick={() => setShow(true)}>
        {newUser ? 'Create Account' : 'Edit Account'}
      </Button>
      {show && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
          onExit={() => setShow(false)}
        >
          <Modal.Header className="create-account-header" Close Button>
            <Modal.Title>
              {newUser ? 'Create Your Account' : 'Edit Account'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className="needs-validation"
              onSubmit={handleSubmit}
              noValidate
            >
              <div>
                  <InputField
                    label="Email"
                    name="email"
                    value={userProfile.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                  {newUser ? (
                    <React.Fragment>
                      <InputField
                        label="Password"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password"
                        required
                      />
                      <InputField
                        label="Confirm Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm Password"
                        required
                      />
                    </React.Fragment>
                ) : (
                <div>
                <Button onClick={handlePasswordButtonClick}>Change Password</Button>
                <ChangePasswordForm
                  userProfile={userProfile}
                  passwordShow={passwordShow}
                  setPasswordShow={setPasswordShow}
                  handleSave={handleSave}
                />
                </div>
              )}
              </div>
              <Row className="mb-3">
                <InputField
                  label="First Name"
                  value={userProfile.firstName}
                  name="firstName"
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  required={true}
                />
                <InputField
                  label="Last Name"
                  value={userProfile.lastName}
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  required={true}
                />
                <InputField 
                  label="Zip Code"
                  value={userProfile.zipCode}
                  name="zipCode"
                  onChange={handleChange}
                  placeholder="Enter your Zip Code"
                  required={true}
                />
              </Row>
              {newUser ? ( <div></div>) : (<Row className="mb-3">
                        <div className="mb-3">
                          <Button className="delete-account-button" type="submit" onClick={handleDeactivate} value={userProfile.isActive}>Deactivate Account</Button>
                        </div>
              </Row>)}
              <div className="mb-3" id="formGridCheckbox">
                <Form.Check
                  onChange={({ target }) =>
                    handleChange({
                      target: {
                        name: target.name,
                        value: !!!userProfile.newsletter,
                      },
                    })
                  }
                  value={userProfile.newsletter}
                  name="newsletter"
                  type="checkbox"
                  label="Sign up for notifications from our Newsletter"
                />
                <div className="mb-3" id="formGridCheckbox">
                  <Form.Check
                    checked={userProfile.commercial}
                    type="checkbox"
                    label="Commercial Account"
                    name="commercial"
                    onChange={({ target }) => {
                      handleChange({
                        target: {
                          name: target.name,
                          value: !userProfile.commercial,
                        },
                      })
                    }}
                  />
                </div>
              </div>
              {userProfile.commercial && (
                <React.Fragment>
                    <InputField
                    label="Organization Name"
                    value={userProfile.organizationName}
                    name="organizationName"
                    onChange={handleChange}
                    placeholder="Organization Name"
                    required={true}
                   />
                    <InputField
                    label="Additional Information"
                    value={userProfile.notes}
                    name="notes"
                    onChange={handleChange}
                    placeholder="Insert additional information about your organization here"
                    required={true}
                   />
                </React.Fragment>
              )}
              <div className="mb-3">
                <Button className="account-submit-button" type="submit">
                  Submit
                </Button>
                <Button
                  className="account-close-button"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>

            
          </Modal.Body>
        </Modal>
      )}
    </Container>
  )
}

export default UserAccountForm;
