import React, { useContext } from "react";
import LoginForm from "./LoginForm";
import "../CSS_Files/master.css";
import GoogleSSOLogin from "../SSO/GoogleSSOLogin";
import image from "../../Icons/ClearLogo.png";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import UserContext from "../../Context/UserContext";

const LoginCard = () => {
  let { loading } = useContext(UserContext);
  loading = loading ? loading : false;
  return (
    <div className="login-background">
      <img src={image} alt="EHWOB" className="login-image" />
      <div className="login-card">
        <LoadingIcon show={loading} />
        <header className="login-header">
          Ev Grants & Incentives Tracker | Log In
        </header>
        <LoginForm />
        <p className="sso-split">Or</p>
        <div className="sso-login">
          <GoogleSSOLogin />
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
