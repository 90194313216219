import axios from "axios";
import Grant from "../Classes/Grant";

const baseUrl = process.env.REACT_APP_API_KEY + "/grants";

export const callAllGrants = async () => {
  try {
    const response = await axios({
      url: `${baseUrl}`,
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const createGrant = async (grant) => {

  try {
    const response = await axios({
      url: `${baseUrl}`,
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: grant,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callUpdateGrant = async (grant) => {
  try {
    const response = await axios({
      url: `${baseUrl}`,
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: grant,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callDeleteGrant = async (grantId) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${grantId}`,
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};
