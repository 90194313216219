import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { arrayToString } from "../../Utilities/general";
import "../CSS_Files/master.css";

const GrantItemInformation = (props) => {
  const { technologies, deadline, status, releaseDate } = props.grant;

  return (
    <Row>
      <Col>
        <label className="label">Status:</label>
        {status ? (
          <label className="open-label"> Open</label>
        ) : (
          <label className="closed-label">Closed</label>
        )}
      </Col>
      <Col>
        {" "}
        <label className="label">Release Date:</label>&nbsp;
        <span className="label-date">{moment(releaseDate).format("L")}</span>
      </Col>
      <Col>
        <label className="label">Deadline:</label>&nbsp;
        <span className="label-date">{moment(deadline).format("L")}</span>
      </Col>

      <Col>
        <label className="label">Technologies: </label>&nbsp;
        <span className="label-technologies">
          {arrayToString(technologies)}
        </span>
      </Col>
    </Row>
  );
};

export default GrantItemInformation;

// <Stack direction="horizontal" gap={3}></Stack>
