import "../CSS_Files/master.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState, useContext } from "react";
import React from "react";
import GrantsContext from "../../Context/GrantsContext";

const SearchBar = () => {
  //this is a search bar component that searches through the grants by name
  //here is the logic for the search
  const [searchTerm, setSearchTerm] = useState("");

  const { updateFilters } = useContext(GrantsContext);

  const handleChange = (event) => {
    const searchValue = event.target.value;
    updateFilters("search", searchValue);
  };

  return (
    <Form className="fiter-search-bar">
      <Form.Control
        type="search"
        placeholder="Search"
        className="filter-search-bar"
        aria-label="Search"
        onChange={handleChange}
      />
    </Form>
  );
};

export default SearchBar;
