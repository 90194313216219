import Card from "react-bootstrap/Card";
import GrantHeader from "./GrantItemHeader";
import GrantInformation from "./GrantItemInformation";
import GrantAccordian from "./GrantItemAccordian";
import "../CSS_Files/master.css";

function GrantItem(props) {
  const { grant } = props;

  return (
    <Card className="card-grant">
      <Card.Header className="background-header">
        <GrantHeader grant={grant} />
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <GrantInformation grant={grant} />
        </Card.Text>
      </Card.Body>
      <GrantAccordian grant={grant} />
    </Card>
  );
}
export default GrantItem;
