import moment from "moment";

export const filterGrants = (grants, filterConditions) => {
  if (!filterConditions) return grants;
  const filters = Object.keys(filterConditions);
  const filteredGrants = grants.filter((grant) => {
    return filters.every((filter) => {
      if (filterConditions[filter].includes("All")) return true;
      switch (filter) {
        case "search":
          return Object.keys(grant).some((key) => {
            if (typeof grant[key] === "string") {
              return grant[key]
                .toLowerCase()
                .includes(filterConditions[filter].toLowerCase());
            } else if (Array.isArray(grant[key])) {
              return grant[key].some((item) =>
                item.name
                  .toLowerCase()
                  .includes(filterConditions[filter].toLowerCase())
              );
            }
            return false;
          });
        case "deadline":
          return filterConditions[filter] < grant[filter];
        case "releaseDate":
          return filterConditions[filter] > grant[filter];
        case "jurisdictions":
          return checkArray(filter, filterConditions, grant);
        case "technologies":
          return checkArray(filter, filterConditions, grant);
        case "counties":
          return checkArray(filter, filterConditions, grant);
        case "industries":
          return checkArray(filter, filterConditions, grant);
        case "organization":
          return grant[filter].name.includes(filterConditions[filter]);
        case "status":
          if (filterConditions[filter].length === 0) return true;
          return (
            (filterConditions[filter].includes("open")
              ? true
              : false) === grant[filter]
          );
        default:
          return true;
      }
    });
  });
  return filteredGrants;
};

const checkArray = (filter, array, grant) => {
  return array[filter].every((f) => grant[filter].find((g) => g.name === f));
};

export const sortGrants = (grants) => {
  const sortedGrants = grants.sort((a, b) => {
    return a.deadline - b.deadline;
  });
};

export const grantStatusCheck = (grants) => {
  const today = moment();
  grants.forEach((grant) => {
    const deadline = moment(grant.deadline);
    const daysLeft = deadline.diff(today, "days");
    if (daysLeft <= 0) {
      grant.status = false;
    } else {
      grant.status = true;
    }
  });
  return grants;
};
