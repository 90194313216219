class Contact {
  constructor() {
    this.name = "";
    this.link = "";
    this.organization = {};
    this.sector = ""
  }
}

export default Contact;
