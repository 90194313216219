import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import LookupContext from "./../../Context/LookupContext";
import DropdownList from "../UI/DropdownList";

function ContactForm(props) {
  //Requires an item to be passed in that will be manipulated
  //Expects an setItem attribute that will be called when fields are changed
  //This should update the state of the item that is passed in
  //Requires saveItem attribute to determine which axios call to make and
  //whether it is a post or put call
  //display is needed to determine what the button will say
  const { item, saveItem, display } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formItem, setFormItem] = useState({ ...item });

  const { lookups } = useContext(LookupContext);
  if (!lookups) return null;
  const { organizations } = lookups;
  if (!organizations) return null;

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setFormItem({ ...formItem, [name]: value });
  };

  const handleSelectUpdate = (e) => {
    const target = {
      name: e.target.name,
      value: e.target.value,
    };
    handleUpdate({ target });
  };

  const handleSave = (e) => {
    e.preventDefault();

    saveItem(formItem);

    handleClose();
  };

  return (
    <Container>
      <Button variant="primary" className="csv-button" onClick={handleShow}>
        {display}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation" onSubmit={handleSave} novalidate>
            <div className="mb-3">
              <label className="label-bold">Name</label>
              <input
                value={formItem.name}
                type="text"
                name="name"
                placeholder="Name"
                className="form-control"
                onChange={handleUpdate}
                autoFocus
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="mb-3">
              <label className="label-bold">Link</label>
              <input
                value={formItem.link}
                type="text"
                name="link"
                placeholder="Link"
                className="form-control"
                onChange={handleUpdate}
                autoFocus
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="mb-3">
              <label className="label-bold">Phone</label>
              <input
                value={formItem.phone}
                type="phone"
                name="phone"
                placeholder="xxx-xxx-xxxx"
                className="form-control"
                onChange={handleUpdate}
                autoFocus
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="mb-3">
              <label className="label-bold">Email</label>
              <input
                value={formItem.email}
                type="email"
                name="email"
                placeholder="SomeEmail@email.com"
                className="form-control"
                onChange={handleUpdate}
                autoFocus
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div>
              <label className="label-bold">Organization</label>
              <DropdownList
                items={organizations}
                name="organization"
                handleUpdate={handleSelectUpdate}
                value={formItem.organization}
              />
            </div>
            <div className="col-12">
              <Button variant="primary" type="submit">
                Submit form
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default ContactForm;
