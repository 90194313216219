import { useContext, useState, useEffect } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faBoltLightning
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../Context/UserContext";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../CSS_Files/master.css";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { callTrackInteraction, callUntrackInteraction, callCheckInteraction } from "../../Calls/interaction";

const GrantHeader = (props) => {
  const { grant } = props;
  // const date = new Date();
  const [ color, setColor] = useState("white");
  const { user } = useContext(UserContext);
  const [toolTipMessage, setToolTipMessage] = useState('Click to Like!');

  useEffect(() => {
    checkIfLiked();
  }, [])

  const checkIfLiked = async () => {
    const data = await callCheckInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "2"});
    if(!data)
    {
      setColor("#7000ff");
    } else {
      setColor("white");
    }
  }

  const likeGrant = async () => {
    await callTrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "2"});
  }

  const unLikeGrant = async (d) => {
    await callUntrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "2"});
  }

  const changeColor = () => {

    if(color==="white"){
      setColor('#7000ff');
      likeGrant();
    } else {
      setColor("white");
      unLikeGrant();
    }
  }

  const calcIsClosingSoon = () => {
    const closingDate = new moment(grant.deadline);
    const today = moment();
    const diff = closingDate.diff(today, "days");
    return 0 < diff && diff < 30;
  };

  const renderClosingTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This grant is closing soon!
    </Tooltip>
  );

  const calcIsNew = () => {
    const today = moment();
    const diff = today.diff(grant.releaseDate, "days");
    if (diff < 30 && diff >= 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row className="header-row">
      <Col>
        <Stack direction="horizontal">
          <div></div>
          <div className="closing-icon-container">
            {calcIsClosingSoon() ? (
              <OverlayTrigger placement="left" overlay={renderClosingTooltip}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="closing-icon"
                  size="xl"
                />
              </OverlayTrigger>
            ) : null}
          </div>
        </Stack>
      </Col>
      <Col sm={10} className="title-Col">
        <h3 className="header-title">
          {calcIsNew() ? (
            <Badge bg="" className="badge-new-icon">
              New
            </Badge>
          ) : null}{" "}
          {grant.name}
        </h3>
      </Col>
      <Col>
        <div>
        <ButtonGroup>
          <Button className="likeButton" 
          onClick={changeColor}
          onMouseEnter={() => setToolTipMessage('Like this grant!')}>
            <OverlayTrigger overlay={<Tooltip className="likeMessage">{toolTipMessage}</Tooltip>}>
            <FontAwesomeIcon
            icon={faBoltLightning}
            className="fa-solid fa-bolt"
            size="lg"
            color={color}
            />
            </OverlayTrigger>
          </Button>
        </ButtonGroup>
        </div>
      </Col>
    </Row>
  );
};

export default GrantHeader;