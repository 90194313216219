import { useRef, useState, useReducer, useEffect } from "react";
import { authenticateUser, verifyCredentials } from "../Calls/user";
import { updateUser, register } from "./../Calls/user";
import User from "../Classes/User";
import { callTrackInteraction, endSession } from "../Calls/interaction";

const verifyToken = async () => {
  const token = JSON.parse(localStorage.getItem("grant_token"));
  if (token && token !== "undefined") {
    const data = await authenticateUser(token);
    return data;
  }
};

const setTokenStorage = async (token) => {
  if (!(!token && token.length === 0)) {
    await localStorage.setItem("grant_token", JSON.stringify(token));
  }
};

const getTokenStorage = () => {
  const token = JSON.parse(localStorage.getItem("grant_token"));
  return token ? token : null;
};

function useUserAuth() {
  const [ssoNew, setSSONew] = useState(false);
  const [user, setUser] = useState();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(null);
  const prevUserData = useRef();

  const checkToken = async () => {
    try {
      setLoading(true);

      const token = getTokenStorage();

      if (token && token !== "undefined") {
        const data = await authenticateUser(token);

        if (data && data.length !== 0) {
          setUser(data);
        }
      }
    } catch (er) {
      console.log(er);
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (userCredentials) => {
    try {
      setLoading(true);
      const data = await verifyCredentials(userCredentials);

      if (!data || data.length === 0) {
        return data.length !== 0 ? data : null;
      }
      await setTokenStorage(data.authToken);

      await setUser(data);

      await callTrackInteraction(data.email);

      return data.length !== 0 ? data : null;
    } catch (er) {
      console.log(er);
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = () => {
  //  endSession(user.email);
    localStorage.removeItem("grant_token");
    setUser({});
  };

  const userRegistration = async (user) => {
    try {
      const data = await register(user);
      if (data) {
        if (data.authToken && data.authToken.length !== 0) {
          await setTokenStorage(data.authToken);
          setUser(data);
        }
      }
      return data;
    } catch (er) {
      console.log(er);
    } finally {
      setLoading(false);
    }
  };

  const handleUserUpdate = async (user) => {
    try {
      prevUserData.current = { ...prevUserData.current, ...user };

      setUser(prevUserData.current);

      await updateUser(user);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return {
    user,
    loginUser,
    logoutUser,
    handleUserUpdate,
    userRegistration,
    ssoNew,
    loading,
    setSSONew,
  };
}

export default useUserAuth;
