import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterCard from "../FilteringGrant/FilterCard";
import GrantList from "../ExistingGrant/GrantList";
import GrantsContext from "../../Context/GrantsContext";
import SearchBar from "../SearchBar/SearchBar";
import GrantForm from "../Forms/GrantForm";
import ContactButton from "../ContactUs/ContactButton";
import Banner from "../Banner/Banner";
import "../CSS_Files/master.css";
import LookupContext from "../../Context/LookupContext";
import Case from "case";
import AdminNavbar from "../UI/Navbar/AdminNavbar";
import Logout from "../UserLogin/Logout";
import RequireAdmin from "../Routes/RequireAdmin";
import GrantButton from "../UI/Button/GrantButton";

function AdminPage(props) {
  const { table } = useParams();
  return (
    <Container fluid="true" className="container-background">
      <Row className="container-1">
        <Col className="col-table-main">
        <GrantButton />
          <AdminNavbar />
          {/* <GrantButton /> */}
          <Logout />
          <br />
          <Row>
            <Col>{props.children}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPage;
