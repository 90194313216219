import React from "react";

const DropdownList = (props) => {
  const { items, name, handleUpdate, value } = props;
  const required =
    props.required === undefined || props.required === null
      ? false
      : props.required;
  if ((!items, !name, !handleUpdate)) {
    return null;
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    value = JSON.parse(value);
    handleUpdate({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <select name={name} onChange={onChange} required>
      <option hidden></option>
      {items.map((item) => {
        return value.name === item.name ? (
          <option value={JSON.stringify(item)} selected>
            {item.name}
          </option>
        ) : (
          <option value={JSON.stringify(item)}>{item.name}</option>
        );
      })}
    </select>
  );
};

export default DropdownList;
