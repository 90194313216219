import React from "react";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import HomeButton from "../UI/Button/HomeButton";
import Banner from "../Banner/Banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../CSS_Files/master.css";

export default function LoadingPage() {
  return (
    <div className="grant-page-container-2">
      <Row>
        <Col className="home-button-container" md={1}>
          <HomeButton />
        </Col>
        <Col className="banner-background-container">
          <Banner />
          <LoadingIcon />
        </Col>
      </Row>
    </div>
  );
}
