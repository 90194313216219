import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../Icons/ClearLogo.png";
import "../CSS_Files/master.css";

function Banner() {
  return (
    <Row>
      <Col>
        <img src={image} alt="Clear Evolve Logo" className="logo-grant-page" />
      </Col>
    </Row>
  );
}

export default Banner;
