import axios from "axios";

const baseUrl = process.env.REACT_APP_API_KEY + "/interactions";

export const callReadInteractions = async () => {
  try {
    const response = await axios({
      url: `${baseUrl}/get-interactions`,
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callGetGrantInteractions = async () => {
  try {
    const response = await axios({
      url: `${baseUrl}/grant-interactions`,
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callCheckInteraction = async (data) => {
  try {

    const response = await axios({
      url: `${baseUrl}/check-interaction`,
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        user_Id: data.user_Id,
        grant_Name: data.grant_Name,
        interaction_type_id: data.interaction_type_id
      }, 
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callTrackInteraction = async (data) => {
  try {
    const response = await axios({
      url: `${baseUrl}/track-interaction`,
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        user_Id: data.user_Id,
        grant_Name: data.grant_Name,
        interaction_type_id: data.interaction_type_id
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callUntrackInteraction = async (data) => {
  try {
    const response = await axios({
      url: `${baseUrl}/undo`,
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        user_Id: data.user_Id,
        grant_Name: data.grant_Name,
        interaction_type_id: data.interaction_type_id
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};
