import React, { useState, useEffect } from "react";
import { callGetGrantInteractions } from "../../Calls/interaction";
import { callReadInteractions } from "../../Calls/interaction";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { saveAs } from 'file-saver'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "../CSS_Files/master.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getGrantBarData, getGrantBarDataSubscriptions, getGrantBarDataInquiries, getGrantBarDataLinks, getGrantBarDataMoreInfo, getGrantStackedBarData, stackedBarPlotOptions } from "./BarCharts";
import { getLoginLineData } from "./LineGraph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.size = 15;

const GraphTable = () => {
  const [interactions, setInteractions] = useState([]);
  const [logins, setLogins] = useState([]);
  const [barPlotData, setBarPlotData] = useState({});
  const [barPlotDataSubs, setBarPlotDataSubs] = useState({});
  const [barPlotDataInquiries, setBarPlotDataInquiries] = useState({});
  const [barPlotDataLinks, setBarPlotDataLinks] = useState({});
  const [barPlotDataMoreInfo, setBarPlotDataMoreInfo] = useState({});
  const [stackedBarPlotData, setStackedBarPlotData] = useState({})
  const [linePlotData, setlinePlotData] = useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dailyLoginPlotData, setDailyLoginPlotData] = useState({})

  const fetchInteractions = async () => {
    const data = await callGetGrantInteractions();
    setInteractions(data);
  };

  const fetchLogins = async () => {
    const loginData = await callReadInteractions();
    setLogins(loginData);
    console.log(loginData)
  };

  useEffect(() => {
    fetchInteractions();
    fetchLogins();
  }, []);


  const getGrantNames = (interactions) => {
    const names = [];
    interactions.map((i) => {
      names.push(i.grant_name);
    });
    return names;
  };

  const grantNames = getGrantNames(interactions);

  const getDailyLoginData = (logins) => {

    let dateArray = new Array()
    const dateNew = new Date(startDate)

    while (dateNew <= endDate) {
      const dateObj = new Date(dateNew)
      const dateStr = `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
      dateArray.push(dateStr)
      dateNew.setDate(dateNew.getDate() + 1)
    }

    logins.map((l) => {
      const loginDateObj = new Date(l.createdAt);
      l.loginDate = `${loginDateObj.getMonth() + 1}/${loginDateObj.getDate()}/${loginDateObj.getFullYear()}`
    })

    const loginDateCounts = logins.reduce((l, d) => {
      if (!l.hasOwnProperty(d.loginDate)) {
        l[d.loginDate] = 0
      }
      l[d.loginDate]++;
      return l
    }, {})

    let dateLoginsArray = new Array()

    dateArray.map((dt) => {
      if (dt in loginDateCounts) {
        dateLoginsArray.push(loginDateCounts[dt])
      } else {
        dateLoginsArray.push(0)
      }
    })
    
    const lineData = {
      labels: dateArray,
      datasets: [
        {
          label: 'Daily Logins',
          data: dateLoginsArray,
          borderColor: '#76fc44',
          fill: false,
        },
      ],
    }

    return lineData
  }

  const loadBarPlotDataLikes = () => {
    const grantBarData = getGrantBarData(interactions, grantNames);
    setBarPlotData(grantBarData);
  };  

  const loadBarPlotDataSubs = () => {
    const grantBarDataSubs = getGrantBarDataSubscriptions(interactions, grantNames);
    setBarPlotDataSubs(grantBarDataSubs);
  };

  const loadBarPlotDataInquiries = () => {
    const grantBarDataInquiries = getGrantBarDataInquiries(interactions, grantNames);
    setBarPlotDataInquiries(grantBarDataInquiries);
  };

  const loadBarPlotDataLinks = () => {
    const grantBarDataLinks = getGrantBarDataLinks(interactions, grantNames);
    setBarPlotDataLinks(grantBarDataLinks);
  };

  const loadBarPlotDataMoreInfo = () => {
    const grantBarDataMoreInfo = getGrantBarDataMoreInfo(interactions, grantNames);
    setBarPlotDataMoreInfo(grantBarDataMoreInfo);
  };

  const loadStackedBarPlotData = () => {
    const grantStackedBarData = getGrantStackedBarData(interactions, grantNames);
    setStackedBarPlotData(grantStackedBarData);
  };

  const loadLinePlotData = () => {
    const loginChartData = getLoginLineData(logins);
    setlinePlotData(loginChartData)
  };

  const loadDailyLoginPlotData = () => {
    const dailyLoginChartData = getDailyLoginData(logins)
    setDailyLoginPlotData(dailyLoginChartData)
  }

  const downloadPlot = (id) => {
    const plot = document.getElementById(id)
    plot.toBlob(function (blob) {
      saveAs(blob, id + '.png')
    })
  }

  return (
    <div style={{backgroundColor: 'white'}}>

      <br />

      <button className="chartGraphButton" onClick={loadBarPlotDataLikes}>
        Likes
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantLikesBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(barPlotData) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantLikesBarPlot" data={barPlotData} options={stackedBarPlotOptions} />
        </div>
      )}

      <br /><br />

      <button className="chartGraphButton" onClick={loadBarPlotDataSubs}>
        Subscriptions
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantSubscriptionsBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(barPlotDataSubs) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantSubscriptionsBarPlot" data={barPlotDataSubs} options={stackedBarPlotOptions} />
        </div>
      )}

      <br /><br />

      <button className="chartGraphButton" onClick={loadBarPlotDataInquiries}>
        Inquiries
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantInquiriesBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(barPlotDataInquiries) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantInquiriesBarPlot" data={barPlotDataInquiries} options={stackedBarPlotOptions} />
        </div>
      )}

      <br /><br />

      <button className="chartGraphButton" onClick={loadBarPlotDataLinks}>
       Link Clicks
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantLinksBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(barPlotDataLinks) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantLinksBarPlot" data={barPlotDataLinks} options={stackedBarPlotOptions} />
        </div>
      )}

      <br /><br />

      <button className="chartGraphButton" onClick={loadBarPlotDataMoreInfo}>
       More Info
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantMoreInfoBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(barPlotDataMoreInfo) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantMoreInfoBarPlot" data={barPlotDataMoreInfo} options={stackedBarPlotOptions} />
        </div>
      )}

      <br /><br />

      <button className="chartGraphButton" onClick={loadLinePlotData}>
        Total Logins
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('loginLinePlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(linePlotData) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Line id="loginLinePlot" data={linePlotData} />
        </div>
      )}

      <br /><br /><br /><br /><br />

      <div style={{marginLeft: 5}}>
        <h5>Select Start Date</h5>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <p>Start Date: { startDate.toDateString() }</p>
        <h5>Select End Date</h5>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <p>You selected: { endDate.toDateString() }</p>

        <button className="chartGraphButton" onClick={loadDailyLoginPlotData}>
          Logins by Date
        </button>
        <ButtonGroup>
            <Button className="downloadButton" onClick={() => downloadPlot('loginByDayLinePlot')}>
            <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
            </Button>
        </ButtonGroup>
        <div className="dateMessage">Set Dates before Clicking</div>
        <br /><br />

        {JSON.stringify(dailyLoginPlotData) !== "{}" && (
        <div
          style={{
            height: "450px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Line id="loginByDayLinePlot" data={dailyLoginPlotData} />
        </div>
      )}

      </div>

      <br /><br />

      <button className="chartGraphButton" onClick={loadStackedBarPlotData}>
        Total Interactions
      </button>
      <ButtonGroup>
          <Button className="downloadButton" onClick={() => downloadPlot('grantStackedBarPlot')}>
          <FontAwesomeIcon icon={faDownload} size="2x" style={{color: "#5f6672",}} />
          </Button>
      </ButtonGroup>
      {JSON.stringify(stackedBarPlotData) !== "{}" && (
        <div
          style={{
            height: "600px",
            width: "900px",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Bar id="grantStackedBarPlot"
            data={stackedBarPlotData}
            options={stackedBarPlotOptions} />
        </div>
        
      )}

      <br /><br />
      
    </div>
  );
};

export default GraphTable;
