const getMonth = (dateString) => {
    const date = new Date(dateString);
    return date.getMonth();
  };

let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];



export const getLoginLineData = (logins) => {
    const loginData = Array(12).fill(0);
    const currentYear = new Date().getFullYear()

    logins.forEach((logins) => {
      const year = new Date(logins.createdAt).getFullYear()
      const monthIndex = getMonth(logins.createdAt);
      if (year === currentYear && monthIndex >= 0 && monthIndex < 12) {
        loginData[monthIndex]++
      }
    });

    const lineData = {
      labels: months,
      datasets: [
        {
          label: "Logins",
          data: loginData,
          borderColor: "#7000ff",
          fill: false,
        },
      ],
    };

    return lineData;
  };
