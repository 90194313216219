import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterCard from "../FilteringGrant/FilterCard";
import GrantList from "../ExistingGrant/GrantList";
import UserProfile from "../UserAccount/UserProfile";
import Banner from "../Banner/Banner";
import "../CSS_Files/master.css";
import { useParams } from "react-router-dom";
import GenericContactUsForm from "../Forms/GenericContactUsForm";
import HomeButton from "../UI/Button/HomeButton";
import AdminButton from "../UI/Button/AdminButton";
import UserContext from "../../Context/UserContext";

function GrantsPage(props) {
  const { ssonew } = useParams();

  return (
    <div className="grant-page-container">
      <Row>
        <Col className="home-button-container" md={3}>
          <HomeButton />
        </Col>
        <Col className="banner-background-container" md={5}>
          <Banner />
        </Col>
        <Col className="admin-button-container" md={3}>
        <AdminButton />
        </Col>
      </Row>
      <Row>
        <Col className="filter-card-col" md={3}>
          <FilterCard />
        </Col>
        <Col md={6}>
          <GrantList />
        </Col>
        <Col md={3}>
          <UserProfile ssoNew={ssonew} />
        </Col>
      </Row>
      <div className="sticky-contact-button">
        <GenericContactUsForm />
      </div>
    </div>
  );
}

export default GrantsPage;
