import React, { useContext } from "react";
import DataTable from "./DataTable";
import LookupContext from "../../Context/LookupContext";
import OrganizationForm from "../Forms/OrganizationForm";
import Organization from "../../Classes/Organization";
import { confirmPopup } from "../../Utilities/general";

function OrganizationTable(props) {
  const { lookups, updateLookup, deleteLookup, addLookup } =
    useContext(LookupContext);
  const { organizations } = lookups;
  if (!organizations)
    return <label className="label-white">No organizations found</label>;

  const handleUpdateSave = (item) => {
    updateLookup(item, "organizations");
  };

  const handleDelete = (id) => {
    if (confirmPopup("organization")) {
      debugger
      deleteLookup(id, "organizations");
    }
  };

  const handleNewSave = (item) => {
    addLookup(item, "organizations");
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "abbreviation",
      text: "Abbreviation",
    },
    {
      dataField: "link",
      text: "Link",
    },
    {
      dataField: "sector",
      text: "Sector",
    },
    //Define what the Actions column will render by calling the Form
    //Component and passing in the object and the handleOnSave function
    {
      dataField: "",
      text: "Edit",
      headerStyle: { width: "3vw" },
      formatter: (cell, row) => (
        <OrganizationForm
          item={row}
          display="Edit"
          saveItem={handleUpdateSave}
        />
      ),
    },
    {
      dataField: "",
      text: "Delete",
      headerStyle: { width: "3vw" },
      formatter: (cell, row) => (
        <button onClick={() => handleDelete(row.id)} className="btn btn-danger">
          Delete
        </button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <OrganizationForm
        item={new Organization()}
        display="Create Organization"
        saveItem={handleNewSave}
      />
      <DataTable data={organizations} columns={columns} />
    </React.Fragment>
  );
}

export default OrganizationTable;
