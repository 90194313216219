import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import LoadingPage from "../Pages/LoadingPage";

function RequireAdmin({ children }) {
  const { user, loading } = useContext(UserContext);

  if (loading || loading === null) {
    return <LoadingPage />;
  }
  if (user && user.admin) {
    return children;
  }
  return <Navigate to="/login" />;
}

export default RequireAdmin;
