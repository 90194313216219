import axios from "axios";
import Case from "case";

const baseUrl = process.env.REACT_APP_API_KEY;

export const callAll = async (path) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${path}`,
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callCreate = async (item, path) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${path}`,
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: item,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callUpdate = async (item, path) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${path}`,
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: item,
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};

export const callDelete = async (id, path) => {
  try {
    const response = await axios({
      url: `${baseUrl}/${path}/${id}`,
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (er) {
    await er;
    console.log(er);
    return [];
  }
};
