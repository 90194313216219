class Organization {
  constructor() {
    this.name = "";
    this.abbreviation = "";
    this.link = "";
    this.sector = "";
  }
}

export default Organization;
