import React from "react";
import "../../CSS_Files/master.css";

const HomeButton = (props) => {
  return (
    <div>
      <form action="https://evolvehouston.org/" method="get" target="_blank">
        <button type="submit" className="glow-on-hover">
          EVOLVE HOME PAGE
        </button>
      </form>
    </div>
  );
};

export default HomeButton;
