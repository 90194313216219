import React, { useContext, useState, useEffect, useRef } from "react";
import UserContext from "../../Context/UserContext";
import DataTable from "./DataTable";
import LookupContext from "../../Context/LookupContext";
import { callUpdate, callCreate, callDelete } from "../../Calls/generic";
import UserAccountForm from "./../Forms/UserAccountForm";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import CSVButton from "../UI/CSVButton";
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "jquery/dist/jquery.min.js";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import $ from "jquery";
import User from './../../Classes/User';


function UserTable(props) {

const { handleUserUpdate } = useContext(UserContext);
const { lookups, setLookups } = useContext(LookupContext);
  const { users } = lookups;
  const [status, setStatus] = useState("Active");

  const changeStatus = (isActive) => {
    if(status === true) {
      setStatus("false")
      //isActive === false;
    }
    else {
      setStatus("true")
      //isActive === true;
    }
  }

  const handleUpdate = async (newUser) => {
    await handleUserUpdate(newUser);
  };

  if (!users) return <LoadingIcon />;

  const handleUpdateSave = (item) => {
    setLookups({
      ...lookups,
      users: users.map((isActive) => (isActive.id === item.id ? item : isActive)),
    });
    callUpdate(item, "users");
  };

  // const handleDelete = (id) => {
  //   if (confirmPopup("user")) {
  //     setLookups({
  //       ...lookups,
  //       users: users.filter((org) => org.id !== id),
  //     });
  //     callDelete(id, "users");
  //   }
  // };

  // const handleNewSave = async (item) => {
  //   const data = await callCreate(item, "users");
  //   const newValues = [...lookups.users, data];
  //   setLookups({ ...lookups, users: newValues });
  // };

  

  const columns = ([
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "15vw" },
    },
    {
      dataField: "zipCode",
      text: "Zip Code",
    },
    {
      dataField: "commercial",
      headerStyle: { width: "6vw" },
      text: "Commercial",
      formatter: (cell, row) => (
        <label className="label-white">{row.commercial ? "Yes" : "No"}</label>
      ),
    },
    {
      dataField: "organization",
      text: "Organization",
      formatter: (cell, row) => (
        <label className="label-white">{row.organizationName}</label>
      ),
    },
    {
      dataField: "isactive",
      headerStyle: { width: "5vw" },
      text: "IsActive",
      formatter: (cell, row) => (
          row.isActive === true ? (
          <button
            onClick={() => changeStatus()}
            className="btn btn-success">
              Active
          </button>
          ) : (
            <button className="btn btn-danger">Not Active</button>
          )
      ),
    },
    {
      dataField: "",
      headerStyle: { width: "7vw" },
      text: "Edit",
      formatter: (cell, row) => (
        <UserAccountForm handleSave={handleUpdate} user={row} display="Edit"/>
      ),
    },
    {
      dataField: "notes",
      text: "Notes",
      headerStyle: { width: "20vw" },
    },
  ]);

  return (
    <React.Fragment>
      <CSVButton name="users" />
      <DataTable data={users} columns={columns} />
    </React.Fragment>
  );
}

export default UserTable;