import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import UserContext from "../../Context/UserContext";
import User from "../../Classes/User";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function GoogleSSOLogin(props) {
  const [profile, setProfile] = useState([]);
  const { loginUser, userRegistration, setSSONew } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = async (res) => {
    let userCredentials = new User();
    userCredentials.email = res.profileObj.email;
    userCredentials.password = res.profileObj.googleId;
    const verifiedUser = await loginUser(userCredentials);
    if (verifiedUser) {
      if (verifiedUser.admin) {
        navigate("/admin");
      } else {
        navigate("/");
      }
    } else {
      const [first, last] = res.profileObj.name.split(" ");
      userCredentials = {
        ...userCredentials,
        firstName: first,
        lastName: last,
      };
      let newUser = await userRegistration(userCredentials);
      if (!(!newUser || newUser.length === 0)) {
        navigate("/newsso/true");
      }
    }
  };

  const onFailure = (er) => {
    console.log("Failure" + er);
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Sign in with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
    />
  );
}

export default GoogleSSOLogin;
