import { useEffect, useState } from "react";
import { callReadInteractions, callReadLogins } from "../Calls/interaction";

const useInteractions = () => {
  const [Interactions, setInteractions] = useState(null);
  const [likes, setLikes] = useState(null);

  const pullInteractions = async () => {
    const data = await callReadInteractions();
    setInteractions(data);
  };

  useEffect(() => {
    pullInteractions();
  }, []);

  return { Interactions };
};

export default useInteractions;
