import jwt_decode from "jwt-decode";

export const tokenDeconstruct = (token) => {
  const decoded = jwt_decode(token);
  return decoded;
};

export const getAuthToken = () => {
  try{
  return JSON.parse(localStorage.getItem("grant_token"))
}
  catch(er){
    console.log(er)
  };
}
