import { useState, useEffect, useRef } from "react";
import {
  createGrant,
  callAllGrants,
  callDeleteGrant,
  callUpdateGrant,
} from "../Calls/grant";

const useGrants = () => {
  const [grants, setGrants] = useState([]);
  const prevGrants = useRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGrants = async () => {
    try {
      const data = await callAllGrants();

      setGrants(data);
      prevGrants.current = data;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);

    }
  };

  const addGrant = (grant) => {
    try {
      createGrant(grant);

      prevGrants.current = [...prevGrants.current, grant];

      sortGrants();

      setGrants([...prevGrants.current]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteGrant = (grantId) => {
    try {
      callDeleteGrant(grantId);
      setGrants(grants.filter((item) => item.id !== grantId));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateGrant = async (grant) => {
    try {
      prevGrants.current = prevGrants.current.map((g) =>
        g.id === grant.id ? grant : g
      );
      setGrants(prevGrants.current);
      await callUpdateGrant(grant);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const sortGrants = () => {
    prevGrants.current = prevGrants.current.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    fetchGrants();
  }, []);

  return {
    grants,
    loading,
    error,
    fetchGrants,
    addGrant,
    deleteGrant,
    updateGrant,
  };
};

export default useGrants;
