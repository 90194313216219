import React from "react";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { getCsv } from "../../Calls/csv";
import "../CSS_Files/master.css";

async function downloadCsv(name) {
  const data = await getCsv(name);

  const hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
  hiddenElement.target = "_blank";

  //providethenamefortheCSVfiletobedownloaded
  hiddenElement.download = `${name}-${moment().format("l_HH_mm_SS")}.csv`;
  hiddenElement.click();
}

const CSVButton = ({ name }) => {
  return (
    <Button className="csv-button" onClick={() => downloadCsv(name)}>
      Download CSV
    </Button>
  );
};

export default CSVButton;
