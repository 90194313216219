import { useState } from "react";
import { Modal, Row, Button } from "react-bootstrap";
import { verifyPassword } from "../../Calls/user";

function ChangePasswordForm(props) {
 // const [passwordShow, setPasswordShow] = useState(false);
  const [currPassword, setCurrPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [validated, setValidated] = useState(false)

  const { handleSave, userProfile, setPasswordShow, passwordShow } = props;

  const handleCurrPasswordChange = (e) => {
    setCurrPassword(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    const data = await verifyPassword({email: userProfile.email, oldPassword: currPassword })
    console.log("!!!!!!!!!!");
    if(data == "1")
    {
      console.log("password verified")
      if (password !== confirmPassword) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
        setPasswordShow(false);

        console.log("Passwords match");
        
        setValidated(true);
        userProfile.password = confirmPassword;
        handleSave(userProfile);
      }
    } else {
      console.log("invalid password")
      alert("Old Password does not match")
    }
  };

  return (
    <>
      {passwordShow && (
        <Modal
          show={passwordShow}
          onHide={() => setPasswordShow(false)}
          backdrop="static"
          keyboard={false}
          onExit={() => setPasswordShow(false)}
        >
          <Modal.Header className="change-password-header" CloseButton>
            <Modal.Title>Change your Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <form>
                <div className="mb-3">
                  <label htmlFor="oldPassword" className="form-label">
                    Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPassword"
                    value={currPassword}
                    onChange={handleCurrPasswordChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {!passwordMatch && (
                    <div className="text-danger">
                      New passwords do not match
                    </div>
                  )}
                </div>
              </form>
            </Row>
            <div className="mb-3">
              <Button
                className="account-submit-button"
                type="submit"
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
              <Button
                className="account-close-button"
                onClick={() => setPasswordShow(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ChangePasswordForm;
