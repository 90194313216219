import React from "react";
import "./LoadingIcon.css";

const LoadingIcon = (props) => {
  let show = props.show;
  if (show === null || show === undefined) {
    show = true;
  }

  return show ? <span className="loader"></span> : null;
};

export default LoadingIcon;
