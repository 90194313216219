import React, { useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import SearchBar from "../SearchBar/SearchBar";
import FilterItem from "./FilterItem";
import LookupContext from "./../../Context/LookupContext";
import "../CSS_Files/master.css";

function FilterCard(props) {
  const [open, setOpen] = useState(false);
  const { lookups } = useContext(LookupContext);
  const { industries, jurisdictions, organizations, counties, technologies } =
    lookups;

  return (
    <Card className="filter-card">
      <Card.Header className="filter-card-header">
        <h5 className="evolve-text-filter-header">Filters</h5>
      </Card.Header>
      <ListGroup variant="flush">
        {counties && (
          <ListGroup.Item>
            <FilterItem name="county" filterName="counties" items={counties} />
          </ListGroup.Item>
        )}
        {industries && (
          <ListGroup.Item>
            <FilterItem
              name="industry"
              filterName="industries"
              items={industries}
            />
          </ListGroup.Item>
        )}
        {organizations && (
          <ListGroup.Item>
            <FilterItem
              name="organization"
              filterName="organizations"
              items={organizations}
            />
          </ListGroup.Item>
        )}
        {technologies && (
          <ListGroup.Item>
            <FilterItem
              name="technology"
              filterName="technologies"
              items={technologies}
            />
          </ListGroup.Item>
        )}
        {jurisdictions && (
          <ListGroup.Item>
            <FilterItem
              name="jurisdiction"
              filterName="jurisdictions"
              items={jurisdictions}
            />
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <FilterItem
            name="status"
            filterName="status"
            items={[{ name: "open" }, { name: "closed" }]}
          />
        </ListGroup.Item>
        <ListGroup.Item className="search-bar-container">
          <SearchBar />
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}

export default FilterCard;
