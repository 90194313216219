import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import UserAccountForm from "../Forms/UserAccountForm";
import User from "../../Classes/User";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import "../CSS_Files/master.css";

const LoginForm = () => {
  const [validated, setValidated] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user, loginUser, handleUserUpdate, userRegistration } =
    useContext(UserContext);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);

      checkCredentials();
    }
  };

  const checkCredentials = async () => {
    try {
      setLoading(true);

      const data = await loginUser(loginCredentials);

      if (data && data.isActive) {
        data.admin ? navigate("/admin") : navigate("/");
      } else {
        const errors = !data && data.isActive ?
        "Invalid Credentials" :
        "Your account is deactivated. Please contact us at EvolveHouston@gmail.com if you have any questions.";
        alert(errors);
      }
    } catch (er) {
      alert("Invalid Credentials");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials({ ...loginCredentials, [name]: value });
  };

  const handleRegister = async (newUser) => {
    const returnedUser = await userRegistration(newUser);
    if (returnedUser) {
     // await handleUserUpdate(returnedUser);
      navigate("/");
    } else {
      alert("Failed to create account");
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="input-form-group" controlId="formBasicEmail">
          <Form.Label className="form-group-text">Email</Form.Label>
          <Form.Control
            placeholder="Enter email"
            onChange={handleChange}
            type="email"
            name="email"
            required
          />
          <Form.Control.Feedback type="invalid">
            Invalid Email Address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="input-form-group" controlId="formBasicPassword">
          <Form.Label className="form-group-text">Password</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="password"
            name="password"
            placeholder="Password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Invalid Password
          </Form.Control.Feedback>
        </Form.Group>
        <div className="login-div">
          <Button className="login-button" type="submit">
            Login
          </Button>
        </div>
      </Form>
      <div className="account-div">
        <UserAccountForm
          user={new User()}
          newUser={true}
          handleSave={handleRegister}
        />
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
