import React, { useContext } from "react";
import DataTable from "./DataTable";
import LookupContext from "../../Context/LookupContext";
import { getDateTime } from "../../Utilities/general";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import useInteractions from "../../Hooks/useInteractions";
import CSVButton from "../UI/CSVButton";

function InteractionTable(props) {
  const { lookups, setLookups } = useContext(LookupContext);
  const { Interactions } = useInteractions();
  if (!Interactions) return <LoadingIcon />;

  if (Interactions.length === 0)
    return <label className="label-white">No Interactions found</label>;

  const columns = [
    {
      dataField: "createdAt",
      text: "Created At",
      headerStyle: { width: "1vw" },
      formatter: (cell) => (
        <label className="label-white">{getDateTime(cell)}</label>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "grant_Name",
      text: "Grant Name",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "1vw" },
    },
  ];

  return (
    <React.Fragment>
      <CSVButton name="interactions" />
      <DataTable data={Interactions} columns={columns} />
    </React.Fragment>
  );
}

export default InteractionTable;
