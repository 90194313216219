import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import GrantsContext from "../../Context/GrantsContext";
import OptionsDropdown from "./OptionsDropdown";

function ContactButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { grants } = useContext(GrantsContext);

  return (
    <Container>
      <Button variant="primary" onClick={handleShow}>
        CONTACT US {""}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title> Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                {" "}
                Name of grant you are contacting us about
              </Form.Label>
              <Form.Select>
                <option>Choose a grant</option>
                <OptionsDropdown items={grants} />
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Your Inquiry</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ContactButton;
