import React, { useState, useEffect } from "react";
import { callGetGrantInteractions } from "../../Calls/interaction";
import LoadingIcon from "../UI/LoadingIcon/LoadingIcon";
import DataTable from "./DataTable";
import CSVButton from "../UI/CSVButton";

const ReportTable = () => {
  const [interactions, setInteractions] = useState([]);

  useEffect(() => {
    async function fetchInteractions() {
      const data = await callGetGrantInteractions();
      setInteractions(data);
    }
    fetchInteractions();
  }, []);

  if (!interactions) return <LoadingIcon />;

  if (interactions.length === 0)
    return <label className="label-white">No Interactions found</label>;

  const columns = [
    {
      dataField: "grant_name",
      text: "Grant Name",
      headerStyle: { width: "1vw" },
      
    },
    {
      dataField: "likes",
      text: "Likes",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "subscriptions",
      text: "Subscriptions",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "inquiries",
      text: "Inquiries",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "link_clicks",
      text: "Link Clicks",
      headerStyle: { width: "1vw" },
    },
    {
      dataField: "more_info_clicks",
      text: "More Info Clicks",
      headerStyle: { width: "1vw" },
    },
  ];

  return (
    <React.Fragment>
      <CSVButton name="Grantinteractions" />
      <DataTable data={interactions} columns={columns} />
    </React.Fragment>
  );
}

export default ReportTable;