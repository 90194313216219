import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import "../CSS_Files/master.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

function DataTable(props) {
  const { data, columns } = props;
  //This takes each column that is given and adds a single consitant style to it
  //You are allowed to define how your column is to be rendered where the DataTable is called
  //you must already implement to formatter field in the column object
  columns.forEach((column) => {
    if (!column.formatter) {
      column.formatter = (cell, row) => (
        <label className="label-white">{cell}</label>
      );
    }
    if (!column.headerFormatter) {
      column.headerFormatter = (column) => (
        <label className="label-white">{column.text}</label>
      );
    }
    if (!column.headerStyle) {
      column.headerStyle = { width: "7vw" };
    }
  });
  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      bodyStyle={{ width: 500, maxWidth: 500, wordBreak: "break-all" }}
      filter={filterFactory()}
    />
  );
}

export default DataTable;
