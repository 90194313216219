import React, { useContext } from "react";
import GrantsContext from "../../Context/GrantsContext";
import GrantItem from "./GrantItem";
import classes from "./GrantList.module.css";
import { filterGrants } from "../../Utilities/grant";
import "../CSS_Files/master.css";

function GrantList(props) {
  const { grants, filters } = useContext(GrantsContext);
  const grantList = filterGrants(grants, filters);

  return (
    <div>
      <ul className={classes.grantList}>
        {grantList && grantList.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <span className="grant-not-found">No Results</span>
          </div>
        ) : (
          grantList.map((grant) => <GrantItem grant={grant} key={grant.id} />)
        )}
      </ul>
    </div>
  );
}

export default GrantList;
