import React from "react";
import { useState, useContext, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Case from "case";
import ContactUsForm from "./../Forms/ContactUsForm";
import { arrayToString } from "../../Utilities/general";
import "../CSS_Files/master.css";
import { callTrackInteraction, callUntrackInteraction, callCheckInteraction } from "../../Calls/interaction";
import UserContext from "../../Context/UserContext";


function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  const [open, setOpen] = useState(false);
  
  const handleDropDown = () => {
    decoratedOnClick();
    setOpen(!open);

  };


  return (
    <button
      type="button"
      style={{ backgroundColor: "transparent", border: "none" }}
      onClick={handleDropDown}
    >
      <Stack className="label">
        {children}
        {open ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </Stack>
    </button>
  );
}

const GrantItemAccordian = (props) => {
  const {
    technologies,
    description,
    link,
    jurisdictions,
    organization,
    deadline,
    releaseDate,
    contacts,
    industries,
    counties,
  } = props.grant;

  const [bgColor, setBGColor] = useState("grey");
  const [toolTipMessage, setToolTipMessage] = useState('Click to Subscribe!');
  const [text, setText] = useState("Subscribe");
  const { user } = useContext(UserContext);
  const { grant } = props;

  useEffect(() => {
    checkIfSubscribed();
  }, [])

  const checkIfSubscribed = async () => {
    const data = await callCheckInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "3"});
    if(!data)
    {
      setText("Subscribed")
      setBGColor("#7000ff");
    } else {
      setBGColor("grey");
    }
  }

  const subscribeToGrant = async () => {
    await callTrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "3"});
  }
  
  const unsubscribeToGrant = async () => {
    await callUntrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id:"3"});
  }

  const grantMoreInfo = async () => {
    await callTrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "5"});
  }

  const trackAccordionClick = async () => {
    console.log("method")
    await callTrackInteraction({user_Id: user.id, grant_Name: grant.name, interaction_type_id: "6"});
  }

  const updateButton = () => {
    if (bgColor === "grey")
    {
      setBGColor("#7000ff")
      subscribeToGrant();
    } else {
      setBGColor("grey")
      unsubscribeToGrant();
    }

    if (text === "Subscribe")
    {
      setText("Subscribed")
    } else {
      setText("Subscribe")
    }
  }



  return (
    <Accordion onClick={trackAccordionClick} className="borderless" defaultActiveKey="1">
      <Card className="borderless">
        <Card.Header className="card-content">
          <CustomToggle eventKey="0">More Information</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div>
              <hr></hr>
              <p>
                <label className="label">Jurisdiction: </label>&nbsp;
                <span className="information">
                {jurisdictions && Case.capital(arrayToString(jurisdictions))}
                </span>
              </p>
              <p>
                <label className="label">Technologies: </label>&nbsp;
                <span className="information">
                  {technologies &&
                    technologies
                      .map((tech) => `${Case.capital(tech.name)}`)
                      .join(", ")}
                </span>
              </p>
              <p>
                <label className="label">County: </label>&nbsp;
                <span className="information">
                  {counties &&
                    counties
                      .map((county) => `${Case.capital(county.name)}`)
                      .join(", ")}
                </span>
              </p>
              <p>
                <label className="label">Description: </label>&nbsp;
                <span className="information">{description}</span>
              </p>
              <p>
                <label className="label">Organization: </label>&nbsp;
                <span className="information">{organization.name}</span>
              </p>
              <hr />
              <Row>
                <Col md={3}>
                  {/* <span className="information">
                    More information: click
                    <a className="information" href={link} target="_blank">
                      {" "}
                      here
                    </a>
                  </span> */}
                  <Button 
                   id="contact-button"
                   className="specific-contact-button" 
                   onClick={() => {
                    grantMoreInfo();
                    window.location.href = link;
                  }}
                  >
                    More Info
                  </Button>
                </Col>
                <Col md={6} className="contact-button-col">
                  <ContactUsForm grant={props.grant} />
                </Col>
                <Col md={3}>
                <Button 
                  id="subscribe-button"
                  className="subscribe-button" 
                  onClick={updateButton}
                  onMouseEnter={() => setToolTipMessage('Subscribe to this grant!')}
                  style={{backgroundColor: bgColor}}
                  >
                    {text}
                  {/* <OverlayTrigger overlay={<Tooltip>{toolTipMessage}</Tooltip>}></OverlayTrigger> */}
                </Button>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default GrantItemAccordian;
