import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../Context/UserContext";
import Logout from "../UserLogin/Logout";
import Card from "react-bootstrap/Card";
import { updateUser } from "../../Calls/user";
import UserAccountForm from "../Forms/UserAccountForm";
import "../CSS_Files/master.css";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../../Icons/truck.png";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);
  const [open, setOpen] = useState(false);
  const handleDropDown = () => {
    decoratedOnClick();
    setOpen(!open);
  };
  return (
    <button
      type="button"
      style={{ backgroundColor: "transparent", border: "none" }}
      onClick={handleDropDown}
    >
      <Stack className="pointer-stack">
        {children}
        {open ? (
          <FontAwesomeIcon className="pointer-color" icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon className="pointer-color" icon={faCaretDown} />
        )}
      </Stack>
      {children}
    </button>
  );
}

function UserProfile(props) {
  const { user, handleUserUpdate } = useContext(UserContext);
  const { ssoNew } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleUpdate = async (newUser) => {
    await handleUserUpdate(newUser);
  };
  return !user ? null : (
    <Card className="profile-card-main">
      <Card.Body>
        <div className="profile-image-header">
          <h3 className="profile-text-header">Profile</h3>
          <div className="image-cropper">
            <img src={image} className="truck-image"></img>
          </div>
        </div>
        <hr></hr>
        <p className="user-account-info-text">
          {"Name: "}
          {user.lastName}, {user.firstName}
        </p>
        <p className="user-account-info-text">Email: {user.email}</p>
        <hr></hr>
        <Row>
          <Col className="user-account-col-buttons">
            {user && (
              <UserAccountForm
                newUser={false}
                user={user}
                open={ssoNew}
                handleSave={handleUpdate}
              />
            )}
          </Col>{" "}
          <Col className="user-account-col-buttons">
            <Logout />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default UserProfile;
