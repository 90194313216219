import { v4 as uuidv4 } from "uuid";
import moment from "moment";


import Case from "case";

export const generateId = () => {
  return uuidv4();
};

export const confirmPopup = (message = "item") => {
  return window.confirm(`Are you sure you want to delete this ${message}?`);
};

export const confirmDeactivation = (user = "item") => {
  return window.confirm('Are you sure you want to deactivate your account?')
}

export const arrayToString = (arr) => {
  let toPrint = "";
  arr.forEach((item) => {
    toPrint += item.name + ", ";
  });
  return toPrint.slice(0, -2);
};
export const getDateLong = (time) => {
  const myTime = new moment(time);
  return myTime.format("l");
};


export const getDateTime = (time) => {
  const myTime = new moment(time);
  return myTime.format("l, LTS");
};

export const getWordSingular = (word) => {
  if (word.includes("ies")) {
    word = word.slice(0, -3) + "y";
  } else if (word[word.length - 1] === "s") {
    word = word.slice(0, -1);
  }
  return Case.capital(word);
};
