import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import {
  callTrackInteraction,
} from "./Calls/interaction";
import { tokenDeconstruct } from "./Utilities/tokens";

const WrappedApp = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
};

const tryCreateLogin = async () => { // !!!DO NOT remove the token logic inside this method it is essential!!!
  const token = localStorage.getItem("grant_token");
  if (!(!token || token.length === 0)) {
    const email = tokenDeconstruct(token).email;

    await callTrackInteraction({user_Id: email, grant_Name: "n/a", interaction_type_id: "1"});
  } else {
    localStorage.removeItem("grant_token");
  }
};

tryCreateLogin();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WrappedApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
