import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import "../CSS_Files/master.css";
import { getWordSingular } from "../../Utilities/general";

function GenericForm(props) {
  //Requires an item to be passed in that will be manipulated
  //Expects an setItem attribute that will be called when fields are changed
  //This should update the state of the item that is passed in
  //Requires saveItem attribute to determine which axios call to make and
  //whether it is a post or put call
  //display is needed to determine what the button will say
  let { item, saveItem, display } = props;
  display = getWordSingular(display);

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formItem, setFormItem] = useState({ ...item });

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setFormItem({ ...formItem, [name]: value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    saveItem(formItem);
    handleClose();
  };

  return (
    <Container>
      <Button className="create-form-button" onClick={handleShow}>
        {display}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{display}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation" onSubmit={handleSave} novalidate>
            <div className="mb-3">
              <label className="label-bold">Name</label>
              <input
                required
                value={formItem.name}
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                onChange={handleUpdate}
                autoFocus
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="mb-3">
              <Button variant="secondary" type="submit">
                Save
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default GenericForm;
