import { v4 as uuid } from "uuid";
class User {
  constructor() {
    this.id = uuid();
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.password = "";
    this.zipCode = "";
    this.city = "";
    this.county = "";
    this.state = "";
    this.newsletter = false;
    this.commercial = false;
    this.organizationName = "";
    this.admin = false;
    this.notes = "";
    this.isActive = true;
  }
}

export default User;
